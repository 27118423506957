<template>
  <v-card
    class="emptyLayoutBg pr-sm-20 pr-md-0 rounded-0"
    flat
    :max-width="checkSm ? 450 : 280"
  >
    <div
      class="text-uppercase pb-16 pb-sm-33 mr-sm-n2 loginTitle--text"
      :class="checkSm ? 'font-large-login text-right' : 'font-row-title'"
    >reset password</div>
    
    <v-form v-model="validForm" ref="form">
      <v-row no-gutters>
        <v-col
          class="d-flex flex-column justify-center pb-4 pb-sm-10"
          :class="{ 'align-end': checkSm }"
          cols="12"
          sm="4"
        >
          <span class="font-normal loginColor--text">Email Address</span>
          <portal-target
            class="field-message font-normal error--text text-sm-right"
            name="emailForResetPassword"
          ></portal-target>
        </v-col>
        
        <v-col class="pb-10 pl-sm-11" cols="12" sm="8">
          <FormField
            v-model="formData.email"
            class="login-form-input"
            :rules="emailRules"
          >
            <template #message="{ key, message }">
              <portal to="emailForResetPassword" v-if="!!message">
                {{ message }}
              </portal>
            </template>
          </FormField>
        </v-col>
        
        <v-col class="pl-12 d-flex justify-end align-center mt-33" cols="12">
          <div class="font-xs textLink--text pr-10">
            <TextLink @click="goToLogin">Login</TextLink>
          </div>
          
          <ActionBtnForDarkBg
            type="submit"
            @click.prevent="submitResetForm"
            :loading="loading"
          >
            Send
          </ActionBtnForDarkBg>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import FormField from '@/components/inputs/FormField'
import ActionBtnForDarkBg from '@/components/buttons/ActionBtnForDarkBg'
import TextLink from '@/components/links/TextLink'

import { sendEmailForResetPassword } from '@/api/auth-routes'

export default {
  name: 'AuthReset',
  inheritAttrs: false,
  components: { FormField, ActionBtnForDarkBg, TextLink },
  data: () => ({
    loading: false,
    validForm: true,
    formData: {
      email: null
    },
    emailRules: [],
  }),
  computed: {
    checkMd() {
      return this.$vuetify.breakpoint.width > 1029
    },
    checkSm() {
      return this.$vuetify.breakpoint.width > 599
    },
  },
  mounted() {
  },
  methods: {
    async submitResetForm() {
      await this.validationForm()
      if (this.$refs.form.validate()) {
        this.loading = true
        const { success, message } = await sendEmailForResetPassword(this.formData)

        if (success !== false) {
          this.$notify({ type: 'success-bg', duration: 15000, text: message })
        } else {
          !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
        }

        this.loading = false
      }
    },
    validationForm() {
      this.emailRules = [
        v => !!v || 'E-mail is required',
        v => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid'
      ]
    },
    goToLogin() {
      this.$router.push({ name: 'AuthLogin' })
    },
    goToRegister() {
      this.$router.push({ name: 'AuthRegister' })
    },
  },
}
</script>
